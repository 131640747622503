import { Drawer as MuiDrawer, styled } from '@mui/material';

export const DrawerStyled = styled(MuiDrawer)(() => ({
    width: '100%',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    '& .MuiPaper-root': {
        width: '100%',
    },
}));
