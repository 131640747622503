import { FC, useContext } from 'react';

import { Box, BoxProps, Container, Stack, useTheme } from '@mui/material';
import { Link, useLocation } from '@remix-run/react';
import {
    Menu as IconMenu,
    Search as IconSearch,
    User as IconUser,
    Moon as IconMoon,
    Sun as IconSun,
} from 'react-feather';
import { useDispatch } from 'react-redux';

import ui from '~/store/ui';

import { mergeSx } from '~/utils/mui';
import { useCurrentUser } from '~/utils/user';
import { persistReaderSetting } from '~/utils/clientDatabase';

import Button from '~/components/atoms/Button';
import { Books as BooksIcon } from '~/components/atoms/Icon';
import IconButton from '~/components/atoms/IconButton';
import CartButton from '~/components/molecules/CartButton';
import HomeButton from '~/components/molecules/HomeButton';
import { ColorModeContext } from '~/contexts/ColorMode';

interface HeaderProps extends BoxProps {
    logoVariant?: 'plus' | 'square';
    signedIn?: boolean;
    hideRightMenu?: boolean;
    overrideColor?: string;
    loginRedirect?: string;
}

const Header: FC<HeaderProps> = ({ loginRedirect, hideRightMenu, signedIn, logoVariant, overrideColor, sx }) => {
    const theme = useTheme();
    const currentUser = useCurrentUser();
    const dispatch = useDispatch();
    const location = useLocation();

    const colorMode = useContext(ColorModeContext);

    const cartLength = currentUser.cart.length || 0;

    return (
        <Box sx={mergeSx({ mb: { xs: 4, md: 8, lg: 16 } }, sx)}>
            <Container
                component="header"
                maxWidth="xxl"
                sx={{
                    p: { xs: 4, sm: 8, md: 12 },
                }}
            >
                <Stack
                    component="nav"
                    direction="row"
                    sx={{
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        zIndex: theme.zIndex.appBar,
                    }}
                >
                    <Stack direction="row" sx={{ alignItems: 'center' }}>
                        <HomeButton
                            variant={logoVariant || 'square'}
                            logoSx={{
                                height: 28,
                                color:
                                    overrideColor ||
                                    (theme.palette.mode === 'light'
                                        ? theme.palette.secondary.main
                                        : theme.palette.common.white),
                            }}
                            sx={{ ml: { xs: 0, md: -3 }, mr: { xs: 2, lg: 4 } }}
                        />
                        {!currentUser.user.organisation.isOrganisationUser && (
                            <>
                                <Button
                                    component={Link}
                                    to="/blogg"
                                    variant="tertiary"
                                    overrideColor={theme.palette.text.primary}
                                    size="large"
                                    sx={{
                                        mr: { xs: 0, lg: 2 },
                                        display: { xs: 'none', md: 'flex' },
                                    }}
                                >
                                    Studiehjelpen
                                </Button>
                                <Button
                                    component={Link}
                                    to="/studiested"
                                    variant="tertiary"
                                    overrideColor={theme.palette.text.primary}
                                    size="large"
                                    sx={{ mr: { xs: 0, lg: 2 }, display: { xs: 'none', md: 'flex' } }}
                                >
                                    Pensumlister
                                </Button>
                                <Button
                                    component={Link}
                                    to="/allvit-pluss"
                                    variant="tertiary"
                                    overrideColor={theme.palette.text.primary}
                                    size="large"
                                    sx={{ mr: { xs: 0, lg: 2 }, display: { xs: 'none', md: 'flex' } }}
                                >
                                    Allvit+
                                </Button>
                            </>
                        )}

                        {cartLength > 0 && hideRightMenu && (
                            <CartButton
                                hideLabel
                                cartLength={cartLength}
                                backgroundColor={theme.palette.background.dark}
                                sx={{
                                    display: { xs: 'none', md: 'flex' },
                                }}
                            />
                        )}
                    </Stack>
                    {!hideRightMenu && (
                        <Stack direction="row" sx={{ alignItems: 'center', display: { xs: 'none', md: 'flex' } }}>
                            {cartLength > 0 && (
                                <>
                                    <CartButton
                                        hideLabel
                                        cartLength={cartLength}
                                        sx={{ mr: 4, display: { xs: 'none', md: 'flex', xl: 'none' } }}
                                    />
                                    <CartButton
                                        cartLength={cartLength}
                                        sx={{ mr: 4, display: { xs: 'none', xl: 'flex' } }}
                                    />
                                </>
                            )}
                            {signedIn && (
                                <>
                                    <Button
                                        component={Link}
                                        to="/profil"
                                        variant="tertiary"
                                        size="large"
                                        overrideColor={theme.palette.text.primary}
                                        startIcon={<IconUser color={theme.palette.text.primary} size={22} />}
                                        sx={{ mr: 4, display: { xs: 'none', xl: 'flex' } }}
                                    >
                                        Profil
                                    </Button>
                                    <IconButton
                                        component={Link}
                                        to="/profil"
                                        aria-label="Profil"
                                        overrideColor={theme.palette.text.primary}
                                        variant="tertiary"
                                        sx={{
                                            mr: 4,
                                            display: { xs: 'none', md: 'flex', xl: 'none' },
                                            height: '52px',
                                            width: '52px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        <IconUser color={theme.palette.text.primary} size={22} />
                                    </IconButton>
                                </>
                            )}
                            <Button
                                component={Link}
                                to="/sok/boker"
                                variant="tertiary"
                                overrideColor={theme.palette.text.primary}
                                state={{ from: location.pathname }}
                                size="large"
                                startIcon={<IconSearch color={theme.palette.text.primary} size={20} />}
                                sx={{ mr: 4, display: { xs: 'none', xl: 'flex' } }}
                            >
                                Søk
                            </Button>
                            <IconButton
                                component={Link}
                                to="/sok/boker"
                                aria-label="Søk"
                                overrideColor={theme.palette.text.primary}
                                variant="tertiary"
                                sx={{
                                    mr: 4,
                                    display: { xs: 'none', md: 'flex', xl: 'none' },
                                    height: '52px',
                                    width: '52px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}
                            >
                                <IconSearch color={theme.palette.text.primary} size={22} />
                            </IconButton>
                            {signedIn && (
                                <>
                                    <IconButton
                                        aria-label={
                                            theme.palette.mode === 'dark' ? 'Bytt til dag-modus' : 'Bytt til nattmodus'
                                        }
                                        overrideColor={theme.palette.text.primary}
                                        variant="tertiary"
                                        onClick={() => {
                                            if (theme.palette.mode === 'dark') {
                                                persistReaderSetting('darkMode', false);
                                                persistReaderSetting('colorProfile', 'default');
                                            } else {
                                                persistReaderSetting('darkMode', true);
                                                persistReaderSetting('colorProfile', 'default');
                                            }
                                            colorMode.toggleColorMode();
                                        }}
                                        sx={{
                                            mr: 4,
                                            height: '52px',
                                            width: '52px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                        }}
                                    >
                                        {theme.palette.mode === 'dark' ? (
                                            <IconSun color={theme.palette.text.primary} size={22} />
                                        ) : (
                                            <IconMoon color={theme.palette.text.primary} size={22} />
                                        )}
                                    </IconButton>
                                    <Button
                                        component={Link}
                                        to="/bokhylle"
                                        size="large"
                                        variant="tertiary"
                                        overrideColor={theme.palette.text.primary}
                                        startIcon={
                                            <BooksIcon sx={{ fontSize: 20, color: theme.palette.text.primary }} />
                                        }
                                        sx={{
                                            display: { xs: 'none', xl: 'flex' },
                                            backgroundColor:
                                                theme.palette.mode === 'light'
                                                    ? theme.palette.background.dark
                                                    : theme.palette.background.light,
                                        }}
                                    >
                                        Bokhylle
                                    </Button>
                                    <IconButton
                                        component={Link}
                                        to="/bokhylle"
                                        aria-label="Bokhylle"
                                        overrideColor={theme.palette.text.primary}
                                        variant="tertiary"
                                        sx={{
                                            display: { xs: 'none', md: 'flex', xl: 'none' },
                                            height: '52px',
                                            width: '52px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor:
                                                theme.palette.mode === 'light'
                                                    ? theme.palette.background.dark
                                                    : theme.palette.background.light,
                                            ':hover': {
                                                backgroundColor:
                                                    theme.palette.mode === 'light'
                                                        ? theme.palette.background.darker
                                                        : theme.palette.background.lighter,
                                            },
                                        }}
                                    >
                                        <BooksIcon sx={{ fontSize: 22, color: theme.palette.text.primary }} />
                                    </IconButton>
                                </>
                            )}
                            {!signedIn && (
                                <>
                                    <Button
                                        component={Link}
                                        to={
                                            loginRedirect
                                                ? `/logg-inn?from=${encodeURIComponent(loginRedirect)}`
                                                : '/logg-inn'
                                        }
                                        variant="tertiary"
                                        overrideColor={theme.palette.text.primary}
                                        size="large"
                                        sx={{
                                            backgroundColor:
                                                theme.palette.mode === 'light'
                                                    ? theme.palette.background.dark
                                                    : theme.palette.background.light,
                                            display: { xs: 'none', xl: 'flex' },
                                        }}
                                    >
                                        Logg inn/registrer deg
                                    </Button>
                                    <IconButton
                                        component={Link}
                                        to={
                                            loginRedirect
                                                ? `/logg-inn?from=${encodeURIComponent(loginRedirect)}`
                                                : '/logg-inn'
                                        }
                                        aria-label="Logg inn"
                                        overrideColor={theme.palette.text.primary}
                                        variant="tertiary"
                                        sx={{
                                            mr: 4,
                                            display: { xs: 'none', md: 'flex', xl: 'none' },
                                            height: '52px',
                                            width: '52px',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            backgroundColor: theme.palette.background.dark,
                                        }}
                                    >
                                        <IconUser color={theme.palette.text.primary} size={22} />
                                    </IconButton>
                                </>
                            )}
                        </Stack>
                    )}
                    <Stack direction="row" sx={{ display: { xs: 'flex', md: 'none' } }}>
                        {cartLength > 0 && <CartButton hideLabel cartLength={cartLength} sx={{ mr: 6 }} />}
                        <IconButton
                            variant="tertiary"
                            size="large"
                            overrideColor={theme.palette.text.primary}
                            onClick={() => {
                                dispatch(ui.actions.openMobileMenu());
                            }}
                            aria-label="Åpne meny"
                        >
                            <IconMenu color={theme.palette.text.primary} size={34} />
                        </IconButton>
                    </Stack>
                </Stack>
            </Container>
        </Box>
    );
};
export default Header;
