import { Box, useTheme } from '@mui/material';
import { useSelector } from 'react-redux';

import { selectUiMenuOpen } from '~/store/selectors';

import AccessibilitySkipButton from '~/components/molecules/AccessibilitySkipButton';
import MobileMenu from '~/components/organisms/MobileMenu';

import { mergeSx } from '~/utils/mui';

import Header from './components/Header';
import Footer from './components/Footer';
import OutsideLayoutProps from './types';

const OutsideLayout = ({
    hideHeader,
    wrapContentInMain = true,
    children,
    signedIn,
    loginRedirect,
    sx,
    ...props
}: OutsideLayoutProps) => {
    const theme = useTheme();
    const menuOpen = useSelector(selectUiMenuOpen);

    const content = wrapContentInMain ? <main id="main">{children}</main> : children;

    return (
        <>
            <Box
                sx={mergeSx(
                    {
                        minHeight: '100vh',
                        backgroundColor: theme.palette.background.default,
                    },
                    sx,
                )}
                {...props}
            >
                <AccessibilitySkipButton />
                <MobileMenu open={menuOpen} />
                {!hideHeader && <Header signedIn={signedIn} loginRedirect={loginRedirect} />}
                {content}
                <Footer />
            </Box>
        </>
    );
};

export default OutsideLayout;
