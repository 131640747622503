import { useContext } from 'react';
import { useLocation, useNavigate } from '@remix-run/react';
import { useDispatch } from 'react-redux';
import { ButtonBase, List, ListItem, Stack, Typography, useTheme } from '@mui/material';
import { X as IconX, Moon as IconMoon, Sun as IconSun } from 'react-feather';

import ui from '~/store/ui';

import Button from '~/components/atoms/Button';
import IconButton from '~/components/atoms/IconButton';

import { useCurrentUser } from '~/utils/user';
import { persistReaderSetting } from '~/utils/clientDatabase';

import { DrawerStyled } from './Styled';
import { ColorModeContext } from '~/contexts/ColorMode';

const MobileMenu = ({ open }: { open: boolean }) => {
    const theme = useTheme();
    const currentUser = useCurrentUser();
    const dispatch = useDispatch();

    const navigate = useNavigate();
    const location = useLocation();

    const colorMode = useContext(ColorModeContext);

    const handleGo = (to: string) => {
        navigate(to, { state: { from: location.pathname } });
        dispatch(ui.actions.closeMobileMenu());
    };

    return (
        <DrawerStyled
            open={open}
            transitionDuration={0}
            ModalProps={{
                keepMounted: true,
            }}
            hideBackdrop
            anchor="right"
            sx={{
                display: { xs: 'block', md: 'none' },
            }}
        >
            <Stack sx={{ height: '100dvh', backgroundColor: theme.palette.background.light }}>
                <Stack sx={{ flex: '1 0 auto' }}>
                    <Stack
                        direction="row"
                        sx={{
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            py: { xs: 4, sm: 8 },
                            px: { xs: 4, sm: 8 },
                            mb: 8,
                        }}
                        role="banner"
                    >
                        <Stack direction="row" sx={{ alignItems: 'center' }}>
                            <IconButton
                                onClick={() => {
                                    dispatch(ui.actions.closeMobileMenu());
                                }}
                                aria-label="Lukk meny"
                                variant="tertiary"
                                size="large"
                                overrideColor={theme.palette.text.primary}
                                sx={{ p: 0 }}
                            >
                                <IconX color={theme.palette.text.primary} size={34} />
                            </IconButton>
                        </Stack>
                    </Stack>

                    {!currentUser.signedIn ? (
                        <List sx={{ py: 0, px: 8 }}>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    onClick={() => {
                                        handleGo('/');
                                    }}
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Start
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    onClick={() => {
                                        handleGo('/sok');
                                    }}
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Søk
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    onClick={() => {
                                        handleGo('/blogg');
                                    }}
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Studiehjelpen
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    onClick={() => {
                                        handleGo('/studiested');
                                    }}
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Pensumlister
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    onClick={() => {
                                        handleGo('/allvit-pluss');
                                    }}
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Allvit+
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    component="a"
                                    href="https://support.allvit.no/"
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Hjelp
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                        </List>
                    ) : (
                        <List sx={{ py: 0, px: 8 }}>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    onClick={() => {
                                        handleGo('/');
                                    }}
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Start
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    onClick={() => {
                                        handleGo('/sok');
                                    }}
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Søk
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    onClick={() => {
                                        handleGo('/profil');
                                    }}
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Min Profil
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                            {!currentUser.user.organisation.isOrganisationUser && (
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ButtonBase
                                        sx={{
                                            pb: 8,
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            '&:focus-visible': {
                                                boxShadow: theme.shadows[24],
                                                borderRadius: theme.radius.all,
                                            },
                                        }}
                                        onClick={() => {
                                            handleGo('/blogg');
                                        }}
                                    >
                                        <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                            Studiehjelpen
                                        </Typography>
                                    </ButtonBase>
                                </ListItem>
                            )}
                            {!currentUser.user.organisation.isOrganisationUser && (
                                <ListItem disablePadding sx={{ display: 'block' }}>
                                    <ButtonBase
                                        sx={{
                                            pb: 8,
                                            width: '100%',
                                            display: 'flex',
                                            flexDirection: 'column',
                                            alignItems: 'center',
                                            '&:focus-visible': {
                                                boxShadow: theme.shadows[24],
                                                borderRadius: theme.radius.all,
                                            },
                                        }}
                                        onClick={() => {
                                            handleGo('/studiested');
                                        }}
                                    >
                                        <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                            Pensumlister
                                        </Typography>
                                    </ButtonBase>
                                </ListItem>
                            )}
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    component="a"
                                    href="https://support.allvit.no/"
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Hjelp
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ButtonBase
                                    sx={{
                                        pb: 8,
                                        width: '100%',
                                        display: 'flex',
                                        flexDirection: 'column',
                                        alignItems: 'center',
                                        '&:focus-visible': {
                                            boxShadow: theme.shadows[24],
                                            borderRadius: theme.radius.all,
                                        },
                                    }}
                                    onClick={() => {
                                        handleGo('/personvern');
                                    }}
                                >
                                    <Typography component="span" variant="h5" sx={{ mb: 1 }}>
                                        Vikår og personvern
                                    </Typography>
                                </ButtonBase>
                            </ListItem>
                        </List>
                    )}
                </Stack>
                <Stack direction="column" sx={{ p: 8 }}>
                    {!currentUser.signedIn ? (
                        <Button
                            fullWidth
                            center
                            onClick={() => {
                                handleGo('/logg-inn');
                            }}
                            variant="primary"
                            size="large"
                        >
                            Logg inn/registrer deg
                        </Button>
                    ) : (
                        <>
                            <Button
                                center
                                variant="secondary"
                                fullWidth
                                size="large"
                                onClick={() => {
                                    if (theme.palette.mode === 'dark') {
                                        persistReaderSetting('darkMode', false);
                                        persistReaderSetting('colorProfile', 'default');
                                    } else {
                                        persistReaderSetting('darkMode', true);
                                        persistReaderSetting('colorProfile', 'default');
                                    }
                                    colorMode.toggleColorMode();
                                }}
                                startIcon={
                                    theme.palette.mode === 'dark' ? (
                                        <IconSun color={theme.palette.text.primary} size={22} />
                                    ) : (
                                        <IconMoon color={theme.palette.cta.main} size={22} />
                                    )
                                }
                                sx={{ mb: 3 }}
                            >
                                {theme.palette.mode === 'dark' ? 'Dag-modus' : 'Nattmodus'}
                            </Button>
                            <Button center component="a" variant="secondary" fullWidth href="/logg-ut" size="large">
                                Logg ut
                            </Button>
                        </>
                    )}
                </Stack>
            </Stack>
        </DrawerStyled>
    );
};

export default MobileMenu;
