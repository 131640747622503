import { SvgIcon, SvgIconProps } from '@mui/material';

const LogoInstagramIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 28 24">
            <path d="M8.46442 0.71875C4.1937 0.71875 0.71875 4.19575 0.71875 8.46753V19.5345C0.71875 23.8058 4.19575 27.2812 8.46753 27.2812H19.5345C23.8058 27.2812 27.2812 23.8043 27.2812 19.5325V8.46442C27.2812 4.1937 23.8043 0.71875 19.5325 0.71875H8.46442ZM21.9688 4.96875C22.5553 4.96875 23.0312 5.44475 23.0312 6.03125C23.0312 6.61775 22.5553 7.09375 21.9688 7.09375C21.3822 7.09375 20.9062 6.61775 20.9062 6.03125C20.9062 5.44475 21.3822 4.96875 21.9688 4.96875ZM14 7.09375C17.8091 7.09375 20.9062 10.1909 20.9062 14C20.9062 17.8091 17.8085 20.9062 14 20.9062C10.1909 20.9062 7.09375 17.8085 7.09375 14C7.09375 10.1915 10.1909 7.09375 14 7.09375ZM14 9.21875C11.3592 9.21875 9.21875 11.3592 9.21875 14C9.21875 16.6408 11.3592 18.7812 14 18.7812C16.6408 18.7812 18.7812 16.6408 18.7812 14C18.7812 11.3592 16.6408 9.21875 14 9.21875Z" />
        </SvgIcon>
    );
};

export default LogoInstagramIcon;
