import { Box, ButtonBase, Stack, Typography, useTheme } from '@mui/material';

import { Link } from '@remix-run/react';

import { visuallyHidden } from '@mui/utils';
import * as React from 'react';

import Button from '~/components/atoms/Button';
import Flex from '~/components/atoms/Flex';
import Heading from '~/components/atoms/Heading';
import {
    LogoInstagram as LogoInstagramIcon,
    LogoLinkedIn as LogoLinkedInIcon,
    LogoTikTok as LogoTikTokIcon,
} from '~/components/atoms/Icon';
import IconButton from '~/components/atoms/IconButton';
import Logo from '~/components/atoms/Logo';
import Paragraph from '~/components/atoms/Paragraph';
import FrontContainer from '~/components/molecules/FrontContainer';

const Footer: React.FC = () => {
    const theme = useTheme();

    return (
        <FrontContainer maxWidth="xxl">
            <Flex component="footer" wrap sx={{ pt: { xs: 6, sm: 12, lg: 34 }, pb: 34, borderTop: theme.border.thin }}>
                <Typography component="h2" sx={visuallyHidden}>
                    Bunntekst
                </Typography>
                <Flex
                    sx={{
                        alignItems: { xs: 'center', sm: 'flex-start' },
                        mb: { xs: 12, md: 8, lg: 0 },
                        width: { xs: 1, lg: 1 / 2 },
                    }}
                >
                    <Stack
                        sx={{
                            alignItems: 'flex-start',
                            width: { xs: 1, sm: 1 / 2 },
                        }}
                    >
                        <ButtonBase
                            sx={{
                                mb: 4,
                                '&:focus-visible': { boxShadow: theme.shadows[24], borderRadius: theme.radius.all },
                            }}
                            component={Link}
                            title="Start"
                            rel="home"
                            to="/"
                        >
                            <Logo
                                sx={{
                                    height: 28,
                                    color:
                                        theme.palette.mode === 'light'
                                            ? theme.palette.secondary.main
                                            : theme.palette.common.white,
                                }}
                            />
                        </ButtonBase>
                        <Paragraph variant="p3" sx={{ pr: 6, maxWidth: '240px' }}>
                            Nyhet! Nå er abonnementet{' '}
                            <Button component={Link} to="/allvit-pluss-for-bedrift" variant="link">
                                Allvit+ for bedrift
                            </Button>{' '}
                            tilgjengelig!
                        </Paragraph>
                    </Stack>
                    <Flex
                        column
                        component="nav"
                        aria-labelledby="footer-social-list"
                        sx={{ justifyContent: { xs: 'flex-end', sm: 'flex-start' } }}
                    >
                        <Heading
                            variant="h6"
                            component="h3"
                            color="text.secondary"
                            id="footer-social-list"
                            mb={4}
                            sx={{ display: { xs: 'none', sm: 'block' } }}
                        >
                            Følg oss
                        </Heading>
                        <Flex>
                            <IconButton
                                sx={{ mr: 2 }}
                                variant="social"
                                size="large"
                                href="https://www.tiktok.com/@allvit.no"
                                aria-label="TikTok"
                                rel="noopener"
                            >
                                <LogoTikTokIcon fontSize="medium" />
                                <Box component="span" sx={visuallyHidden}>
                                    TikTok
                                </Box>
                            </IconButton>
                            <IconButton
                                sx={{ mr: 2 }}
                                variant="social"
                                size="large"
                                href="https://www.instagram.com/allvit.no/"
                                aria-label="Instagram"
                                rel="noopener"
                            >
                                <LogoInstagramIcon fontSize="medium" />
                                <Box component="span" sx={visuallyHidden}>
                                    Instagram
                                </Box>
                            </IconButton>
                            <IconButton
                                variant="social"
                                size="large"
                                href="https://www.linkedin.com/company/allvit-no/"
                                aria-label="LinkedIn"
                                rel="noopener"
                            >
                                <LogoLinkedInIcon fontSize="medium" />
                                <Box component="span" sx={visuallyHidden}>
                                    LinkedIn
                                </Box>
                            </IconButton>
                        </Flex>
                    </Flex>
                </Flex>
                <Box
                    sx={{
                        pr: { xs: '0', sm: 16, md: 32 },
                        mb: { xs: 12, sm: 0 },
                        width: { xs: 1, sm: 1 / 2, lg: 'auto' },
                        flex: 1,
                    }}
                >
                    <Heading variant="h6" component="h3" mb={4}>
                        Om Allvit
                    </Heading>
                    <Paragraph variant="p3">
                        Allvit leverer digitale pensumbøker på nett i PDF- og EPUB format for å gi deg en lettere og mer
                        effektiv studiehverdag. Våre bøker veier ingenting. Du kan ta egne notater, markere så mye du
                        vil, søke direkte i bøkene og kopiere ut avsnitt – alt på en plass.{' '}
                        <Button href="/om-allvit" variant="link">
                            Les mer om oss
                        </Button>
                    </Paragraph>
                </Box>
                <Box sx={{ width: { xs: 1, sm: 1 / 2, lg: '200px' } }}>
                    <Heading variant="h6" component="h3" mb={4}>
                        Kundeservice
                    </Heading>
                    <Stack sx={{ flexDirection: { xs: 'row', sm: 'column' } }}>
                        <Paragraph variant="p3" sx={{ mb: 0, lineHeight: '1.8', width: { xs: 1 / 2, sm: 1 } }}>
                            <Button
                                href="mailto:support@allvit.no"
                                variant="link"
                                overrideColor={theme.palette.text.primary}
                            >
                                support@allvit.no
                            </Button>
                            <br />
                            <Button
                                href="https://support.allvit.no/knowledge"
                                variant="link"
                                overrideColor={theme.palette.text.primary}
                            >
                                Hjelpesider
                            </Button>
                        </Paragraph>
                        <Paragraph variant="p3" sx={{ lineHeight: '1.8', width: { xs: 1 / 2, sm: 1 } }}>
                            <Button href="/personvern" variant="link" overrideColor={theme.palette.text.primary}>
                                Personvern og cookies
                            </Button>
                            <br />
                            <Button href="/kjopsvilkar" variant="link" overrideColor={theme.palette.text.primary}>
                                Kjøps- og bruksvilkår
                            </Button>
                        </Paragraph>
                    </Stack>
                </Box>
            </Flex>
        </FrontContainer>
    );
};
export default Footer;
