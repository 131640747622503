import { SvgIcon, SvgIconProps } from '@mui/material';

const LogoTikTokIcon = (props: SvgIconProps) => {
    return (
        <SvgIcon {...props} viewBox="0 0 42 42">
            <path
                d="M29.5248 0.422119L22.833 0.422158C22.833 8.50792 22.7711 28.49 22.7711 28.49C22.7711 32.3004 19.7351 34.5 16.8849 34.5C14.0348 34.5 11.2776 32.3315 11.2776 28.49C11.2776 24.6485 15.0571 22.9135 16.6681 22.9135C17.8763 22.9135 18.403 23.0994 19.0226 23.2854V15.8501C18.3981 15.6481 17.6594 15.5713 16.6681 15.5713C9.60472 15.5713 3.9674 21.8009 4.15234 28.49C4.36903 36.3278 10.658 41.0989 17.0399 41.0989C23.4218 41.0989 29.6797 35.6772 29.6797 28.49V14.0223C31.7244 15.8812 36.0925 17.3372 39.5004 17.3372V10.4287C35.411 10.4287 29.5248 6.95882 29.5248 0.422119Z"
                fill="currentColor"
            />
        </SvgIcon>
    );
};

export default LogoTikTokIcon;
