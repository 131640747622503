import { Badge, Theme, useTheme, alpha, SxProps } from '@mui/material';

import { Link } from '@remix-run/react';

import { ShoppingCart as IconShoppingCart } from 'react-feather';

import Button from '~/components/atoms/Button';
import IconButton from '~/components/atoms/IconButton';
import { mergeSx } from '~/utils/mui';

interface CartButtonProps {
    hideLabel?: boolean;
    cartLength?: number;
    backgroundColor?: string;
    sx?: SxProps<Theme>;
}

const CartButton = ({ sx, hideLabel, cartLength, backgroundColor }: CartButtonProps) => {
    const theme = useTheme();

    if (!hideLabel) {
        return (
            <Button
                component={Link}
                to="/handlekurv"
                variant="tertiary"
                size="large"
                overrideColor={theme.palette.text.primary}
                startIcon={
                    <Badge
                        sx={{
                            fontWeight: '700',
                            '& .MuiBadge-badge': {
                                top: '-1px',
                                border: `2px solid ${theme.palette.background.default}`,
                                backgroundColor: theme.palette.primary.main,
                                color: theme.palette.primary.contrastText,
                            },
                        }}
                        badgeContent={cartLength || '0'}
                    >
                        <IconShoppingCart color={theme.palette.text.primary} size={22} />
                    </Badge>
                }
                sx={mergeSx(
                    {
                        '&:hover': {
                            backgroundColor:
                                theme.palette.mode === 'light'
                                    ? alpha(theme.palette.common.black, 0.05)
                                    : theme.palette.background.paper,
                        },
                        '&:hover .MuiBadge-badge': {
                            border: `2px solid ${theme.palette.background.default}`,
                        },
                    },
                    sx,
                )}
            >
                Handlekurv
            </Button>
        );
    }

    return (
        <IconButton
            component={Link}
            to="/handlekurv"
            aria-label="Handlekurv"
            variant="tertiary"
            overrideColor={theme.palette.text.primary}
            sx={mergeSx(
                {
                    display: 'flex',
                    height: '52px',
                    width: '52px',
                    justifyContent: 'center',
                    alignItems: 'center',
                },
                sx,
            )}
        >
            <Badge
                sx={{
                    fontWeight: '700',
                    '& .MuiBadge-badge': {
                        top: '-1px',
                        border: `2px solid ${backgroundColor || theme.palette.background.default}`,
                        backgroundColor: theme.palette.primary.main,
                        color:
                            theme.palette.mode === 'light' ? theme.palette.text.primary : theme.palette.secondary.main,
                    },
                }}
                badgeContent={cartLength || '0'}
            >
                <IconShoppingCart color={theme.palette.text.primary} size={22} />
            </Badge>
        </IconButton>
    );
};

export default CartButton;
