import { FC } from 'react';

import { Box, BoxProps, styled } from '@mui/material';

export interface FlexProps extends BoxProps {
    align?: string;
    column?: boolean;
    justify?: string;
    relative?: boolean;
    wrap?: boolean;
}

const FlexStyled = styled(Box, {
    shouldForwardProp: (prop: string) => !['align', 'column', 'justify', 'relative', 'wrap'].includes(prop),
})<FlexProps>(({ align, column, justify, relative, wrap }) => ({
    display: 'flex',
    ...(align && {
        alignItems: align,
    }),
    ...(column && {
        flexDirection: 'column',
    }),
    ...(justify && {
        justifyContent: justify,
    }),
    ...(relative && {
        position: 'relative',
    }),
    ...(wrap && {
        flexWrap: 'wrap',
    }),
}));

const Flex: FC<FlexProps> = ({ ...props }: FlexProps) => {
    return <FlexStyled {...props} />;
};

export default Flex;
